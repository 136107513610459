<template>
  <div class="page">
    <!-- tab -->
    <div class="tabBar">
      <div
        :class="['tab-item', { active: curIndex == index }]"
        @click="selectTabItem(item, index)"
        v-for="(item, index) in tabList"
        :key="index"
      >
        {{ item.name }}
        <div class="line" v-if="curIndex == index"></div>
      </div>
    </div>
    <!-- 基本信息 -->
    <div class="basicInfo" v-if="this.curIndex == 0">
      <img src="./img/jbqk-bg.jpg" alt="" />
    </div>
    <!-- 投票问卷 -->
    <div class="voteQuestionnaire" v-if="this.curIndex == 1">
      <v-list
        :finished="finished"
        @onLoad="onLoad"
        :finishedText="finishedText"
        ref="list"
      >
        <div
          class="item"
          @click="toQuDetail(item)"
          v-for="(item, index) in listData"
          :key="index"
        >
          <div class="item-l">
            <img :src="item.imageUrls" />
          </div>
          <div class="item-r">
            <div class="name">{{ item.title }}</div>
            <div class="endTime">结束时间：{{ item.endDate }}</div>
            <div class="item-r-b">
              <div class="status-ing" v-if="item.quStatus == 2">进行中</div>
              <div class="btn-ing" v-if="item.quStatus == 2">去投票</div>
              <div class="status-end" v-if="item.quStatus == 3">已结束</div>
              <div class="btn-end" v-if="item.quStatus == 3">查看结果</div>
            </div>
          </div>
        </div>
      </v-list>
    </div>
    <!-- 社区选举 -->
    <div class="communityElections" v-if="this.curIndex == 2">
      <v-list
        :finished="finished"
        @onLoad="onLoad"
        :finishedText="finishedText"
        ref="list"
      >
        <div
          class="item"
          v-for="(item, index) in listData"
          :key="index"
          @click="toDetail(item)"
        >
          <div class="item-l">
            <img :src="item.pictureUrl" />
          </div>
          <div class="item-r">
            <div class="name">{{ item.electionName }}</div>
            <div class="endTime" v-if="item.electionStatus == 2">
              结束时间：{{ item.publicityEndTime }}
            </div>
            <div class="item-r-b">
              <div class="status-ing" v-if="item.electionStatus == 1">
                进行中
              </div>
              <div class="status-end" v-else>已结束</div>
            </div>
          </div>
        </div>
      </v-list>
    </div>
    <!-- 社区抽查 -->
    <div class="communitySpotCheck" v-if="this.curIndex == 3">
      <v-list
        :finished="finished"
        @onLoad="onLoad"
        :finishedText="finishedText"
        ref="list"
      >
        <div
          class="item"
          @click="toRouteTitle(item)"
          v-for="(item, index) in listData"
          :key="index"
        >
          <div class="item-l">
            <div class="name">{{ item.topicTitle || "" }}</div>
            <div class="item-l-b">
              <div class="time">{{ item.createTime }}</div>
              <div class="sum">
                <img src="./img/lookIcon.png" />
                <div class="num">{{ item.readCount }}</div>
              </div>
            </div>
          </div>
          <div class="item-r">
            <img :src="item.topicPicture" />
          </div>
        </div>
      </v-list>
    </div>
  </div>
</template>

<script>
import {
  getTopicListURL,
  getQuTopicListUrl,
  userInfoUrl,
  getGovernListUrl,
} from "./api.js";
import wx from "weixin-js-sdk";
export default {
  name: "buildPlatform",
  data() {
    return {
      curIndex: 0,
      tabList: [
        {
          name: "基本情况",
        },
        {
          name: "投票问卷",
        },
        {
          name: "社区选举",
        },
        {
          name: "社区抽查",
        },
      ],
      finishedText: "没有更多了",
      finished: false,
      listData: [],
      requestData: {
        curPage: 1,
        pageSize: 20,
      },
      userInfo: {},
      fromPath: null,
    };
  },
  computed: {
    userId() {
      return this.$store.state.userId;
    },
    tenantId() {
      return this.$store.state.tenantId;
    },
    houseId() {
      return this.$store.state.houseId;
    },
    communityId() {
      return this.$store.state.communityId;
    },
    roomId() {
      return this.$store.state.houseId;
    },
    openId() {
      return this.$store.state.openId;
    },
  },
  async created() {
    if (localStorage.getItem("curIndex") != null) {
      this.curIndex = Number(localStorage.getItem("curIndex"));
    } else {
      this.curIndex = 0;
    }

    if (this.userId != "0") {
      this.getUserInfo();
    }
  },
  mounted() {},
  beforeDestroy() {},
  methods: {
    // 投票问卷跳转详情
    toQuDetail(item) {
      if (item.isOnlyHouseOwner == 1 && Number(this.userInfo.identity) != 0) {
        this.$toast({
          message: `该投票问卷仅业主可回答`,
          duration: 2000,
        });
        return;
      }
      // 未开始
      if (item.quStatus === 1) {
        this.$toast({
          message: `该投票问卷暂未开始`,
          duration: 2000,
        });
        return;
      }
      let params = {
        id: item.id,
        hasAnswer: item.hasAnswer,
      };
      localStorage.setItem("curIndex", 1);
      if (item.quType == 3) {
        if (wx.miniProgram) {
          wx.miniProgram.navigateTo({
            url: `/xubPages/questionnaireDetail531/index?params=${JSON.stringify(
              params
            )}`,
          });
        }
      } else {
        if (wx.miniProgram) {
          wx.miniProgram.navigateTo({
            url: `/xubPages/voteDetail531/index?params=${JSON.stringify(
              params
            )}`,
          });
        }
      }
    },
    // 社区抽查跳转详情
    toRouteTitle(item) {
      localStorage.setItem("curIndex", 3);
      let params = {
        id: item.id,
      };
      if (wx.miniProgram) {
        wx.miniProgram.navigateTo({
          url: `/subPages/topic/topicTitle?params=${JSON.stringify(params)}`,
        });
      }
    },
    // 社区选举跳转详情
    toDetail(item) {
      localStorage.setItem("curIndex", 2);
      this.$router.push({
        name: "communityElectionsDetail",
        query: {
          electionId: item.electionId,
        },
      });
    },
    // 获取用户信息
    async getUserInfo() {
      let params = {
        userId: this.userId,
      };
      const res = await this.$axios.get(userInfoUrl, {
        params,
      });
      if (res.code === 200) {
        this.userInfo = res.data;
      }
    },
    // tab栏切换
    selectTabItem(item, index) {
      this.curIndex = index;
      this.finished = true;
      this.listData = [];
      this.requestData.curPage = 1;
      this.requestData.pageSize = 20;
      this.onLoad();
    },
    onLoad() {
      this.getList();
    },
    getList() {
      this.listData = [];
      if (this.curIndex == 3) {
        let params = {
          curPage: this.requestData.curPage,
          pageSize: this.requestData.pageSize,
          tenantId: this.tenantId,
          houseId: this.houseId || this.communityId || undefined,
          sortField: "NEW",
          topicType: "27",
        };
        this.$axios.get(`${getTopicListURL}`, { params }).then((res) => {
          if (res.code === 200) {
            if (res.data.pages <= res.data.current) {
              this.finished = true;
            } else {
              this.$refs.list._data.loading = false;
            }
            this.finishedText = "没有更多了";
            if (res.data.records.length === 0) {
              this.finishedText = "";
              return;
            }
            res.data.records.forEach((item) => {
              this.listData.push(item);
            });
          }
        });
      } else if (this.curIndex == 1) {
        let params = {
          curPage: this.requestData.curPage,
          pageSize: this.requestData.pageSize,
          tenantId: this.tenantId,
          userId: this.userId,
          shelfSts: 1, // 0 待发布 1 已发布 2 已下架
          houseId: this.houseId || this.communityId || undefined,
          quType: "3,4",
        };
        this.$axios.get(`${getQuTopicListUrl}`, { params }).then((res) => {
          if (res.code === 200) {
            if (res.data.pages <= res.data.current) {
              this.finished = true;
            } else {
              this.$refs.list._data.loading = false;
            }
            this.finishedText = "没有更多了";
            if (res.data.records.length === 0) {
              this.finishedText = "";
              return;
            }
            res.data.records.forEach((item) => {
              this.listData.push(item);
            });
          }
        });
      } else if (this.curIndex == 2) {
        let params = {
          curPage: this.requestData.curPage,
          pageSize: this.requestData.pageSize,
          tenantId: this.tenantId,
          houseId: this.houseId || this.communityId || undefined,
        };
        this.$axios.get(`${getGovernListUrl}`, { params }).then((res) => {
          if (res.code === 200) {
            if (res.data.pages <= res.data.current) {
              this.finished = true;
            } else {
              this.$refs.list._data.loading = false;
            }
            this.finishedText = "没有更多了";
            if (res.data.records.length === 0) {
              this.finishedText = "";
              return;
            }
            res.data.records.forEach((item) => {
              this.listData.push(item);
            });
          }
        });
      }
    },
  },
};
</script>

<style scoped lang='less'>
.page {
  width: 100%;
  min-height: 100%;
  box-sizing: border-box;
  background: #fafafa;
  .communitySpotCheck {
    .item {
      width: 100%;
      height: 186px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.06);
      display: flex;
      .item-l {
        flex: 1;
        height: 100%;
        box-sizing: border-box;
        padding: 26px 30px 0 30px;
        position: relative;
        .name {
          font-size: 32px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.85);
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .item-l-b {
          width: 100%;
          display: flex;
          align-items: center;
          font-size: 24px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #b4b4b4;
          position: absolute;
          box-sizing: border-box;
          bottom: 28px;
          left: 0;
          padding-left: 30px;
          .time {
            white-space: nowrap;
          }
          .sum {
            flex: 1;
            display: flex;
            justify-content: center;
            align-items: center;
            img {
              display: block;
              width: 28px;
              height: 26px;
            }
          }
        }
      }
      .item-r {
        padding: 16px 30px 20px 0;
        box-sizing: border-box;
        width: 256px;
        height: 100%;
        img {
          border-radius: 12px;
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  .communityElections,
  .voteQuestionnaire {
    width: 100%;
    padding: 0 32px;
    box-sizing: border-box;
    .item {
      width: 100%;
      height: 220px;
      background: #ffffff;
      box-shadow: 0px 4px 32px 0px rgba(66, 114, 153, 0.1);
      border-radius: 16px;
      overflow: hidden;
      display: flex;
      margin-bottom: 26px;
      .item-l {
        width: 224px;
        height: 100%;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .item-r {
        flex: 1;
        padding: 30px 0 0 28px;
        box-sizing: border-box;
        position: relative;
        .name {
          font-size: 32px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: rgba(0, 0, 0, 0.85);
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          padding-bottom: 2px;
        }
        .endTime {
          font-size: 28px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.5);
        }
        .item-r-b {
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          height: 62px;
          padding-left: 28px;
          box-sizing: border-box;
          .btn-ing {
            width: 194px;
            height: 62px;
            background: linear-gradient(180deg, #fdc76f 0%, #faad3d 100%);
            box-shadow: 0px 4px 8px 0px rgba(225, 225, 225, 0.5);
            border-radius: 16px 0px 16px 0px;
            font-size: 26px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #ffffff;
            line-height: 62px;
            text-align: center;
          }
          .btn-end {
            width: 194px;
            height: 62px;
            background: linear-gradient(180deg, #ededed 0%, #d8d8d8 100%);
            box-shadow: 0px 4px 8px 0px rgba(225, 225, 225, 0.5);
            border-radius: 16px 0px 16px 0px;
            font-size: 26px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: rgba(0, 0, 0, 0.25);
            line-height: 62px;
            text-align: center;
          }
          .status-ing {
            width: 99px;
            height: 40px;
            background: #fff3eb;
            border-radius: 4px;
            border: 2px solid #ffc7a4;
            text-align: center;
            font-size: 24px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #fe6f16;
            line-height: 40px;
          }
          .status-end {
            width: 99px;
            height: 40px;
            background: rgba(0, 0, 0, 0.04);
            border-radius: 4px;
            border: 2px solid rgba(0, 0, 0, 0.25);
            text-align: center;
            font-size: 24px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: rgba(0, 0, 0, 0.5);
            line-height: 40px;
          }
        }
      }
    }
  }
  .basicInfo {
    width: 100%;
    img {
      width: 100%;
    }
  }
  .tabBar {
    height: 120px;
    display: flex;
    align-items: center;
    font-size: 28px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.5);
    padding: 0 30px;
    box-sizing: border-box;
    .tab-item {
      flex: 1;
      width: 100%;
      height: 120px;
      text-align: center;
      line-height: 120px;
      position: relative;
      .line {
        width: 34px;
        height: 6px;
        background: #fe9514;
        border-radius: 3px;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: 25px;
      }
    }
    .active {
      font-size: 32px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #fe9514;
    }
  }
}
</style>